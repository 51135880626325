import {
  Grid, TextField, Button, Card, Box,
  Divider, Typography,
} from '@mui/material';
import RoutePath from 'app/router/RoutePath';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import store from 'app/store';
import {
  COOKIE_JWT, CARRIER_CODE, MAIN_HOST, AZURE_SMAL, VOYA_SMAL, AFLAC_SMAL,
  BST_CARRIER, CLIENT_PORTAL_URL,
} from 'common/AppConstant';
import ceresByMPLogo from 'asset/ceres-logo.png';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';

import 'styles.css';
import {
  VOYA, AFLAC, BST, ERROR_LOGIN,
} from 'common/Constants';
import { login } from './state/service';
import PasswordChange from './passwordChange';

export default function Login() {
  const jwt = useSelector((state) => state.auth.jwt);
  const requiredPasswordChange = useSelector((state) => state.auth.requiredPasswordChange);
  const carrierCode = useSelector((state) => state.auth.carrierCode) || BST_CARRIER;
  const errorMsg = useSelector((state) => state.auth.errorMsg);
  /* eslint-disable-next-line prefer-destructuring */
  const pathname = window.location.pathname;
  const isAflacUrl = [AFLAC.PATHNAME].includes(pathname);

  const navigate = useNavigate();
  const { control, handleSubmit, getValues } = useForm();

  const logIn = (data) => {
    store.dispatch(login(data));
  };

  const navigateTo = (path) => {
    navigate(path);
  };

  const handleAzureSso = () => {
    window.location.replace(`${MAIN_HOST}/saml/login?idp=${AZURE_SMAL}`);
  };

  const handleVoyaSso = () => {
    window.location.replace(`${MAIN_HOST}/saml/login?idp=${VOYA_SMAL}`);
  };

  const handleAflacSso = () => {
    window.location.replace(`${MAIN_HOST}/saml/login?idp=${AFLAC_SMAL}`);
  };

  const navigateToClientPortal = () => {
    window.location.replace(CLIENT_PORTAL_URL);
  };

  useEffect(() => {
    if (jwt) {
      navigateTo(RoutePath.app.scenario.fullpath);
      localStorage.setItem(COOKIE_JWT, jwt);
    }
  }, [jwt, navigateTo]);

  useEffect(() => {
    const savedJwt = localStorage.getItem(COOKIE_JWT);
    if (savedJwt && savedJwt !== 'null') {
      navigateTo(RoutePath.app.scenario.fullpath);
    }
  }, [navigateTo]);

  useEffect(() => {
    if (carrierCode) {
      localStorage.setItem(CARRIER_CODE, carrierCode);
    }
  }, [carrierCode]);

  return (
    requiredPasswordChange
      ? (
        <PasswordChange
          username={getValues('username')}
          password={getValues('password')}
        />
      )
      : (
        <Box
          margin={-1}
          sx={{
            minHeight: '100vh',
            width: 'auto',
            backgroundColor: '#EEF0F3',
          }}
        >
          {!isAflacUrl && (
            <Grid container>
              <Grid item container xs={5} sx={{ margin: '0 auto' }}>
                <Card sx={{ background: '#FFF89F', marginTop: '1rem', padding: '1rem' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ marginRight: '0.5rem' }}>
                      <WarningIcon />
                    </Box>
                    <Typography sx={{ fontSize: '0.915rem' }}>
                      Notice - Starting 9/12, you will need to log in via the MultiPlan Client
                      Portal. To configure your account and ensure that you don’t lose access,
                      click on ‘Continue with Client Portal’ below.
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          )}
          <Grid container padding={2}>
            <Grid item xs={4.5} />
            <Grid item xs={3} marginTop={isAflacUrl ? 11 : 1}>
              <Card sx={{ borderRadius: '1rem', width: '370px', paddingBottom: '24px' }}>
                <Grid
                  container
                  spacing={2}
                  direction="column"
                >
                  <Grid item xs={12}>
                    <img
                      src={ceresByMPLogo}
                      alt="Ceres By MultiPlan"
                      height="auto"
                      width="155"
                      style={{ display: 'block', margin: '0 auto' }}
                    />
                  </Grid>
                  {([AFLAC.PATHNAME, BST.PATHNAME, BST.PATHNAME_DEFAULT].includes(pathname))
                    && (
                      <Grid container spacing={2} direction="column" paddingLeft={2}>
                        <Grid item xs={12} marginY={2}>
                          <Controller
                            name="username"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                id="input-username"
                                label="Email"
                                {...field}
                                fullWidth
                                error={error}
                                helperText={error ? error.message : null}
                                InputLabelProps={{ style: { color: '#353B43' } }}
                                className="custom-input-border"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} marginBottom={0} marginTop={-2}>
                          <Controller
                            name="password"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                id="input-password"
                                label="Password"
                                {...field}
                                fullWidth
                                type="password"
                                error={error}
                                helperText={error ? error.message : null}
                                InputLabelProps={{ style: { color: '#353B43' } }}
                                className="custom-input-border"
                              />
                            )}
                          />
                        </Grid>
                        {errorMsg && (
                          <Box
                            display="flex"
                            flexWrap="wrap"
                            justifyContent="center"
                            alignItems="center"
                            paddingTop="16px"
                          >
                            <ErrorIcon sx={{ color: ERROR_LOGIN }} fontSize="small" />
                            <Typography style={{ color: ERROR_LOGIN }}>
                              &nbsp;
                              {errorMsg}
                            </Typography>
                          </Box>
                        )}
                        <Grid item xs={12}>
                          <Button variant="contained" fullWidth onClick={handleSubmit(logIn)} style={{ textTransform: 'none', borderRadius: '0.4rem' }}> Sign In </Button>
                        </Grid>
                        {/* hide bst login with azure temporarily */}
                        {([].includes(pathname))
                          && (
                            <Grid container direction="column" paddingLeft={2} marginTop={2}>
                              <Divider>or</Divider>
                              <Grid item xs={12} marginBottom={2} marginTop={2}>
                                <Button variant="outlined" fullWidth onClick={() => handleAzureSso()}>Continue with BST Azure </Button>
                              </Grid>
                            </Grid>
                          )}
                        {isAflacUrl
                          ? (
                            <Grid item xs={12} marginBottom={2}>
                              <Button variant="outlined" fullWidth onClick={() => handleAflacSso()}> Continue with Aflac SSO </Button>
                            </Grid>
                          ) : (
                            <Grid item xs={12} marginBottom={2}>
                              <Button variant="outlined" fullWidth onClick={() => navigateToClientPortal()}>Continue with Client portal</Button>
                            </Grid>
                          )}
                      </Grid>
                    )}
                  {([VOYA.PATHNAME].includes(pathname))
                    && (
                      <Grid item xs={12} marginBottom={2}>
                        <Button variant="outlined" fullWidth onClick={() => handleVoyaSso()}> Continue with Voya </Button>
                      </Grid>
                    )}
                </Grid>
              </Card>
              <Box
                component="footer"
                sx={{
                  paddingTop: '35px',
                  color: '#353B43',
                  marginTop: '325px',
                  marginBottom: '0.25rem',
                }}
              >
                <Grid
                  container
                  spacing={32}
                  direction="column"
                  lineHeight="1.5"
                  sx={{
                    paddingLeft: '90px',
                    width: 'calc(100% + 400px)',
                    overflow: 'hidden',
                  }}
                >
                  <Typography fontSize="0.75rem">
                    MultiPlan Inc. All Rights Reserved
                    <br />
                    CPT © 2023 American Medical Association. All Rights Reserved.
                    <br />
                    Fee schedules, relative value units, conversion factors and/or related
                    components are not assigned by the AMA, are not part of CPT, and the AMA is
                    not recommending their use. The AMA does not directly or indirectly practice
                    medicine or dispense medical services. The AMA assumes no liability for data
                    contained or not contained herein.
                    <br />
                    CPT is a registered trademark of the American Medical Association
                    <br />
                    <br />
                    <p>
                      U.S. Government Rights
                      <br />
                      This product includes CPT and/or CPT® Changes which are commercial technical
                      data, which was developed exclusively at private expense by the American
                      Medical Association, 330 North Wabash Avenue, Chicago, Illinois 60611. The
                      American Medical Association does not agree to license CPT to the Federal
                      Government based on the license in FAR 52.227-14 (Data Rights - General) and
                      DFARS 252.227-7015 (Technical Data - Commercial Items) or any other license
                      provision. The American Medical Association reserves all rights to approve
                      any license with any Federal agency.
                    </p>
                  </Typography>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )

  );
}
