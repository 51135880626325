export const COOKIE_JWT = 'JWT';

export const MAIN_HOST = process.env.REACT_APP_BACKEND_HOST;

export default COOKIE_JWT;

export const FORMAT_DATE = 'MM/DD/YYYY';

export const CARRIER_CODE = 'carrierCode';

export const BST_CARRIER = 'BST';

export const AZURE_SMAL = process.env.REACT_APP_AZURE_SMAL;

export const VOYA_SMAL = process.env.REACT_APP_VOYA_SMAL;

export const AFLAC_SMAL = process.env.REACT_APP_AFLAC_SMAL;

export const AFLAC_CARRIER_CODE = 'AFLAC';

export const CLIENT_PORTAL_URL = process.env.REACT_APP_CLIENT_PORTAL;
