import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  Box,
  Card, Divider, Grid, IconButton, InputAdornment, MenuItem, TextField,
  Typography, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import store from 'app/store';
import axios from 'axios';
import StyledDropzone from 'common/components/styledDropzone';
import { FILE_MISSING, THIS_FIELD_IS_REQUIRED } from 'common/ErrorConstants';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import VALIDATION_DUPLICATION from 'common/util/ValidationUtil';
import { MAIN_HOST } from 'common/AppConstant';
import BottomBar from 'features/navigation/bottomBar';
import * as Constant from 'common/Constants';
import { validateWholePercentage, validateNonNegativeInteger, validatePositiveInteger } from 'common/validations';
import { useNavigate } from 'react-router-dom';
import RoutePath from 'app/router/RoutePath';
import CancelScenarioDialog from './common/cancelScenarioDialog';
import { resetCensusFile, updateFormState } from './state/reducer';
import {
  saveTempFile,
  getIndustryList, getRegionList, getStatusById, saveScenario,
} from './state/service';
import 'styles.css';
import CensusFileReminderDialog from './common/censusFileReminder';

export default function ScenarioInformation(props) {
  const { bottomBarFunctions } = props;
  const { handleCancelCreation, goToNextPage } = bottomBarFunctions;
  const scenario = useSelector((state) => state.scenario.scenario);
  const industryList = useSelector((state) => state.scenario.industryList);
  const regionList = useSelector((state) => state.scenario.regionList);
  const scenarioId = useSelector((state) => state.scenario.scenarioId);
  const tempS3FilePath = useSelector((state) => state.scenario.tempS3FilePath);
  const tempFileName = useSelector((state) => state.scenario.tempFileName);
  const userId = useSelector((state) => state.auth.userId);
  const processStatus = useSelector((state) => state.scenario.processStatus);
  const [disableButton, setDisableButton] = React.useState(null);
  const [disableButtonUploading, setDisableButtonUploading] = React.useState(false);
  const [openInvalidDialog, setOpenInvalidDialog] = React.useState(false);
  const [isGuidelinesOpen, toggleGuidelinesDialog] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [sourceScreen, setSourceScreen] = useState(null);
  const [censusFileCheck, setCensusFileCheck] = useState(false);
  const formStateValue = useSelector((state) => state.scenario.isModified);

  const navigate = useNavigate();
  const [fieldErrors, setFieldErrors] = useState({
    numOfMedicalPlans: false,
    averageMaxOutOfPocket: false,
    percentageOfEmployeesInHdhp: false,
    averageDeductible: false,
    employerName: false,
    name: false,
    numOfMembers: false,
    industry: false,
    region: false,
    censusFile: false,
  });

  const {
    control, handleSubmit, reset, formState, setValue, getValues, trigger,
  } = useForm({
    defaultValues: {
      ...scenario,
      numOfMedicalPlans: scenario?.numOfMedicalPlans?.toString() || '',
      percentageOfEmployeesInHdhp: scenario?.percentageOfEmployeesInHdhp?.toString() || '',
      averageDeductible: scenario?.averageDeductible?.toString() || '',
      averageMaxOutOfPocket: scenario?.averageMaxOutOfPocket?.toString() || '',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    trigger();
    Object.keys(getValues()).forEach((fieldName) => {
      setValue(fieldName, getValues()[fieldName]);
    });
  }, []);

  useEffect(() => {
    if (formState.dirtyFields.censusFile && Object.keys(formState.dirtyFields).length === 1
    && !censusFileCheck) {
      store.dispatch(updateFormState(false));
    } else {
      store.dispatch(updateFormState(formState.isDirty));
    }
  }, [formState]);

  const leavingPage = (source) => {
    const actions = {
      onClickFirstButton: handleCancelCreation,
      onClickGoToDemographic: goToNextPage,
    };
    if (actions[source]) {
      actions[source]();
      store.dispatch(updateFormState(false));
    }
  };

  const leavingScenarios = (source) => {
    if (formStateValue) {
      store.dispatch(updateFormState(formState.isDirty));
      setIsDialogOpen(true);
      setSourceScreen(source);
    } else {
      leavingPage(source);
    }
  };

  const save = (saveData) => {
    setDisableButton(true);
    const scenarioSaveDto = {
      scenario: { ...saveData, id: scenarioId },
      tempS3FilePath,
    };
    if (tempFileName !== null) {
      scenarioSaveDto.scenario.censusFileName = tempFileName;
    }
    store.dispatch(saveScenario(scenarioSaveDto))
      .unwrap().then((response) => {
        if ('id' in response && Number.isInteger(response.id) && response.id > 0) {
          const link = `${RoutePath.app.scenario.subpage.navigate.path}?id=${response.id}`;
          navigate(link);
        }
      }).finally(() => {
        setDisableButton(false);
      });
  };

  const handleCensusFileDelete = () => {
    store.dispatch(resetCensusFile());
    setCensusFileCheck(false);
  };

  const onCensusSampleDownload = () => {
    const link = document.createElement('a');
    link.download = 'census_sample.csv';
    link.href = '/census_sample.csv';
    link.click();
  };

  const checkFieldsError = () => {
    const { censusFile, ...otherFieldErrors } = fieldErrors;
    return scenario?.censusFileName ? Object.values(otherFieldErrors).some((error) => error)
      : Object.values(fieldErrors).some((error) => error);
  };

  useEffect(() => {
    store.dispatch(getIndustryList());
    store.dispatch(getRegionList());
  }, []);

  useEffect(() => {
    reset({
      ...scenario,
      numOfMedicalPlans: scenario?.numOfMedicalPlans?.toString() || '',
      percentageOfEmployeesInHdhp: scenario?.percentageOfEmployeesInHdhp?.toString() || '',
      averageDeductible: scenario?.averageDeductible?.toString() || '',
      averageMaxOutOfPocket: scenario?.averageMaxOutOfPocket?.toString() || '',
    });
  }, [scenario]);

  useEffect(() => {
    if (processStatus === Constant.STATUS_CODE.PYTHON_INVALID) {
      setOpenInvalidDialog(true);
    }
  }, [processStatus]);

  useEffect(() => {
    let interval;
    const fetchStatus = async () => {
      store.dispatch(getStatusById(scenarioId)).unwrap().finally(() => {
        if (processStatus !== Constant.STATUS_CODE.RUNNING) {
          clearInterval(interval);
        }
      });
    };
    if (processStatus === Constant.STATUS_CODE.RUNNING) {
      interval = setInterval(() => {
        fetchStatus();
      }, 2000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [processStatus]);

  return (
    <main>
      <Grid container spacing={2} padding={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={7} variant="addPaddingBottom" marginTop={1}>
                  <Typography variant="h5">
                    Basic information
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="h6">
                        Required Information
                      </Typography>
                    </Grid>
                    {(scenarioId === 0 || ('createdBy' in scenario && userId === scenario.createdBy))
                    && (
                      <Grid item xs={6}>
                        <Controller
                          name="privateScenario"
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Box className="mask-box">
                                <Box
                                  className="mask"
                                  style={{
                                    transform: `translateX(${getValues('privateScenario') === true ? 0 : '90px'})`,
                                  }}
                                />
                                <Button
                                  value
                                  disableRipple
                                  variant="text"
                                  {...field}
                                  error={error}
                                  sx={{ color: getValues('privateScenario') === true ? Constant.WHITE : Constant.DISABLE_GRAY }}
                                  onClick={() => {
                                    setValue('privateScenario', !getValues('privateScenario'), { shouldDirty: true });
                                  }}
                                >
                                  Private
                                </Button>
                                <Button
                                  value={false}
                                  disableRipple
                                  variant="text"
                                  {...field}
                                  error={error}
                                  sx={{ color: getValues('privateScenario') === false ? Constant.WHITE : Constant.DISABLE_GRAY }}
                                  onClick={() => {
                                    setValue('privateScenario', !getValues('privateScenario'), { shouldDirty: true });
                                  }}
                                >
                                  Public
                                </Button>
                              </Box>
                            </Box>
                          )}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} container spacing={2} variant="addPaddingBottom">
                  <Grid item xs={6}>
                    <Controller
                      name="name"
                      rules={{
                        required: THIS_FIELD_IS_REQUIRED,
                        validate: async (value) => {
                          if (value) {
                            const isFound = await axios.get(
                              `${MAIN_HOST}/scenario/find?name=${value}`,
                            );
                            return VALIDATION_DUPLICATION(
                              isFound,
                              scenarioId,
                            );
                          }

                          return null;
                        },
                      }}
                      control={control}
                      defaultValue=""
                      render={({ field, fieldState: { error } }) => {
                        useEffect(() => {
                          setFieldErrors((prevErrors) => (
                            { ...prevErrors, name: !!error }));
                        }, [error]);
                        return (
                          <TextField
                            id="input_scenarioName"
                            label="Scenario name"
                            {...field}
                            required
                            fullWidth
                            error={error && formState.touchedFields.name}
                            helperText={error ? error.message : null}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="employerName"
                      rules={{
                        required: THIS_FIELD_IS_REQUIRED,
                      }}
                      control={control}
                      defaultValue=""
                      render={({ field, fieldState: { error } }) => {
                        useEffect(() => {
                          setFieldErrors((prevErrors) => (
                            { ...prevErrors, employerName: !!error }));
                        }, [error]);
                        return (
                          <TextField
                            id="input_employerName"
                            label="Employer name"
                            {...field}
                            required
                            fullWidth
                            error={error && formState.touchedFields.employerName}
                            helperText={error ? error.message : null}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="numOfMembers"
                      control={control}
                      defaultValue=""
                      rules={{
                        validate: validatePositiveInteger,
                        required: THIS_FIELD_IS_REQUIRED,
                      }}
                      render={({ field, fieldState: { error } }) => {
                        useEffect(() => {
                          setFieldErrors((prevErrors) => (
                            { ...prevErrors, numOfMembers: !!error }));
                        }, [error]);
                        return (
                          <TextField
                            id="input_numberOfMembers"
                            label="Company Headcount"
                            {...field}
                            fullWidth
                            error={error && (formState.touchedFields.numOfMembers || error.type !== 'required')}
                            helperText={error ? error.message : null}
                            onWheel={(e) => e.target.blur()}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="industry"
                      rules={{
                        required: THIS_FIELD_IS_REQUIRED,
                      }}
                      control={control}
                      defaultValue=""
                      render={({ field, fieldState: { error } }) => {
                        useEffect(() => {
                          setFieldErrors((prevErrors) => (
                            { ...prevErrors, industry: !!error }));
                        }, [error]);
                        return (
                          <TextField
                            id="input_industry"
                            label="Select Industry"
                            select
                            {...field}
                            required
                            fullWidth
                            error={error && formState.touchedFields.industry}
                            helperText={error ? error.message : null}
                          >
                            {industryList.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="region"
                      rules={{
                        required: THIS_FIELD_IS_REQUIRED,
                      }}
                      control={control}
                      defaultValue=""
                      render={({ field, fieldState: { error } }) => {
                        useEffect(() => {
                          setFieldErrors((prevErrors) => (
                            { ...prevErrors, region: !!error }));
                        }, [error]);
                        return (
                          <TextField
                            id="input_region"
                            label="Select Region"
                            select
                            {...field}
                            required
                            fullWidth
                            error={error && formState.touchedFields.region}
                            helperText={error ? error.message : null}
                          >
                            {regionList.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        );
                      }}
                    />
                  </Grid>

                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Medical Plan Information (Optional)
                  </Typography>
                </Grid>
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={6}>
                    <Controller
                      name="numOfMedicalPlans"
                      control={control}
                      defaultValue=""
                      rules={{ validate: validateNonNegativeInteger }}
                      render={({ field, fieldState: { error } }) => {
                        useEffect(() => {
                          setFieldErrors((prevErrors) => (
                            { ...prevErrors, numOfMedicalPlans: !!error }));
                        }, [error]);

                        return (
                          <TextField
                            id="input_numberOfMedicalPlans"
                            label="How many medical plans does the group have?"
                            {...field}
                            fullWidth
                            error={error}
                            helperText={error ? error.message : null}
                            onWheel={(e) => e.target.blur()}
                            InputLabelProps={{ shrink: true }}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="hasHdhp"
                      control={control}
                      defaultValue=""
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          id="input_hasHDHP"
                          label="Does group currently have a HDHP?"
                          select
                          {...field}
                          fullWidth
                          error={error}
                          helperText={error ? error.message : null}
                          InputLabelProps={{ shrink: true }}
                        >
                          <MenuItem value>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="averageDeductible"
                      control={control}
                      defaultValue=""
                      rules={{ validate: validateNonNegativeInteger }}
                      render={({ field, fieldState: { error } }) => {
                        useEffect(() => {
                          setFieldErrors((prevErrors) => (
                            { ...prevErrors, averageDeductible: !!error }));
                        }, [error]);

                        return (
                          <TextField
                            id="input_averageDeductible"
                            label="What is the average deductible?"
                            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                            {...field}
                            fullWidth
                            error={error}
                            helperText={error ? error.message : null}
                            onWheel={(e) => e.target.blur()}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="percentageOfEmployeesInHdhp"
                      control={control}
                      defaultValue=""
                      rules={{ validate: validateWholePercentage }}
                      render={({ field, fieldState: { error } }) => {
                        useEffect(() => {
                          setFieldErrors((prevErrors) => (
                            { ...prevErrors, percentageOfEmployeesInHdhp: !!error }));
                        }, [error]);

                        return (
                          <TextField
                            id="input_percentageOfEmployeesInHDHP"
                            label="Percentage of employees participating in HDHP?"
                            {...field}
                            fullWidth
                            error={error}
                            helperText={error ? error.message : null}
                            onWheel={(e) => e.target.blur()}
                            InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                            InputLabelProps={{ shrink: true }}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="averageMaxOutOfPocket"
                      control={control}
                      defaultValue=""
                      rules={{ validate: validateNonNegativeInteger }}
                      render={({ field, fieldState: { error } }) => {
                        useEffect(() => {
                          setFieldErrors((prevErrors) => (
                            { ...prevErrors, averageMaxOutOfPocket: !!error }));
                        }, [error]);

                        return (
                          <TextField
                            id="input_averageMaxOutOfPocket"
                            label="What is the average maximum out of pocket?"
                            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                            {...field}
                            fullWidth
                            error={error}
                            helperText={error ? error.message : null}
                            onWheel={(e) => e.target.blur()}
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12} variant="addPaddingBottom">
                  <Typography variant="h5">
                    Upload your data
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" mystyle="paraHeader">
                    Eligibility Census
                  </Typography>
                  <Typography variant="body1">
                    Load a census file to create better matched cohort results.
                    <br />
                    The file must be in .CSV format and&nbsp;
                    <Box
                      component="span"
                      sx={{ cursor: 'pointer', textDecoration: 'underline', color: '#1976D2' }}
                      onClick={() => toggleGuidelinesDialog(true)}
                    >
                      follow the formatting guidelines.
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {scenario?.censusFileName
                    ? (
                      <Grid
                        bgcolor={grey[200]}
                        borderRadius={10}
                        height={40}
                        display="flex"
                        alignItems="center"
                        paddingLeft={2}
                        container
                      >
                        <Grid xs={11}>
                          <Typography display="inline" style={{ alignSelf: 'center' }}>
                            {scenario.censusFileName}
                          </Typography>
                        </Grid>
                        <Grid xs={1}>
                          <IconButton aria-label="delete" style={{ float: 'right' }}>
                            <DeleteIcon onClick={handleCensusFileDelete} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )
                    : (
                      <Controller
                        render={({
                          field: { onChange },
                          fieldState: { error },
                        }) => {
                          useEffect(() => {
                            setFieldErrors((prevErrors) => (
                              { ...prevErrors, censusFile: !!error }));
                          }, [error]);
                          return (
                            <StyledDropzone
                              onChange={(files) => {
                                if (typeof onChange === 'function') {
                                  onChange(files[0]);
                                }
                              }}
                              onDropAccepted={(files) => {
                                if (files[0] !== undefined && files[0] !== null) {
                                  setDisableButtonUploading(true);
                                  store.dispatch(saveTempFile(files[0])).unwrap().finally(() => {
                                    setDisableButtonUploading(false);
                                  });
                                  setCensusFileCheck(true);
                                }
                              }}
                              error={error && formState.touchedFields.censusFile}
                            />
                          );
                        }}
                        name="censusFile"
                        control={control}
                        rules={{ required: FILE_MISSING }}
                        defaultValue=""
                      />
                    )}
                </Grid>
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      Not sure how to structure the eligibility census file?
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Button onClick={onCensusSampleDownload} variant="contained" style={{ float: 'right' }} size="small">
                      <FileDownloadIcon />
                      &nbsp;Download Template
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <BottomBar
              firstButtonDisplayName={scenarioId && scenarioId > 0 ? 'BACK TO MENU' : 'CANCEL CREATION'}
              secondButtonDisplayName="SAVE & RUN SCENARIO"
              onClickFirstButton={() => leavingScenarios('onClickFirstButton')}
              onClickSecondButton={handleSubmit(save)}
              secondButtonDirtyFlag={disableButton
                || checkFieldsError()
                || disableButtonUploading
                || !formState.isDirty
                || processStatus === Constant.STATUS_CODE.RUNNING}
              showGoToDemographic
              onClickGoToDemographic={() => leavingScenarios('onClickGoToDemographic')}
              goToDemographicDisableFlag={disableButton
                || processStatus !== Constant.STATUS_CODE.SUCCESS}
              processStatus={processStatus}
            />
          </Grid>
          <CensusFileReminderDialog
            content={Constant.CENSUS_FILE_REMINDER_CONTENT.FILE_INVALID}
            open={openInvalidDialog}
            onClose={() => {
              setOpenInvalidDialog(false);
            }}
          />
        </Grid>
      </Grid>
      <Dialog open={isGuidelinesOpen} onClose={() => toggleGuidelinesDialog(false)}>
        <DialogTitle>Census File Guidelines</DialogTitle>
        <Divider />
        <DialogContent>
          <Box
            component="span"
            sx={{ cursor: 'pointer', textDecoration: 'underline', color: '#1976D2' }}
            onClick={onCensusSampleDownload}
          >
            Download the template
          </Box>
          <List sx={{ listStyleType: 'disc', pl: 2 }}>
            <ListItem sx={{
              display: 'list-item', pt: 0, pb: 0, pl: 0,
            }}
            >
              <ListItemText>File must be .CSV format</ListItemText>
            </ListItem>
            <ListItem sx={{
              display: 'list-item', pt: 0, pb: 0, pl: 0,
            }}
            >
              <ListItemText>File must match the template provided</ListItemText>
            </ListItem>
            <ListItem sx={{
              display: 'list-item', pt: 0, pb: 0, pl: 0,
            }}
            >
              <ListItemText>File must contain a minimum of 100 rows of data</ListItemText>
            </ListItem>
            <ListItem sx={{
              display: 'list-item', pt: 0, pb: 0, pl: 0,
            }}
            >
              <ListItemText>File must have only four columns of data</ListItemText>
              <List sx={{
                listStyleType: 'disc', pt: 0, pb: 0, pl: 2,
              }}
              >
                <ListItem sx={{
                  display: 'list-item', p: 0,
                }}
                >
                  <ListItemText>Age/DOB</ListItemText>
                </ListItem>
                <ListItem sx={{
                  display: 'list-item', p: 0,
                }}
                >
                  <ListItemText>Gender</ListItemText>
                </ListItem>
                <ListItem sx={{
                  display: 'list-item', p: 0,
                }}
                >
                  <ListItemText>Relation</ListItemText>
                </ListItem>
                <ListItem sx={{
                  display: 'list-item', p: 0,
                }}
                >
                  <ListItemText>Personstate</ListItemText>
                </ListItem>
              </List>
            </ListItem>
            <ListItem sx={{
              display: 'list-item', pt: 0, pb: 0, pl: 0,
            }}
            >
              <ListItemText>Data must use the following formatting</ListItemText>

              <List sx={{
                listStyleType: 'disc', pt: 0, pb: 0, pl: 2,
              }}
              >
                <ListItem sx={{
                  display: 'list-item', p: 0,
                }}
                >
                  <ListItemText>Age - Numeric or DOB - MM/DD/YYYY</ListItemText>
                </ListItem>
                <ListItem sx={{
                  display: 'list-item', p: 0,
                }}
                >
                  <ListItemText>Gender - M, F, O</ListItemText>
                </ListItem>
                <ListItem sx={{
                  display: 'list-item', p: 0,
                }}
                >
                  <ListItemText>Relation - E, S, C</ListItemText>
                </ListItem>
                <ListItem sx={{
                  display: 'list-item', p: 0,
                }}
                >
                  <ListItemText>
                    Personstate - Two letter abbreviation for U.S. state (e.g., AZ)
                  </ListItemText>
                </ListItem>
              </List>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => toggleGuidelinesDialog(false)}
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <CancelScenarioDialog
        formState={formState}
        isOpen={isDialogOpen}
        leavingPage={leavingPage}
        sourceScreen={sourceScreen}
        onClose={() => setIsDialogOpen(false)}
      />
    </main>
  );
}
