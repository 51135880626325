import React, { useEffect, useRef } from 'react';
import {
  Card, Divider, Grid, Stack, Typography, Box,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  ResponsiveContainer, PieChart, Pie,
} from 'recharts';

import BottomBar from 'features/navigation/bottomBar';
import ExportImage from 'common/components/exportImage';
import store from 'app/store';
import * as Constants from 'common/Constants';
import renderActiveShape from './common/renderActiveShape';
import { getDemographicById } from './state/service';
import CarrierDisclaimer from './common/carrierDisclaimer';

const GENDER_COLORS = {
  MALE: { COLOR: '#0088FE', POS: 1 },
  FEMALE: { COLOR: '#00C49F', POS: 2 },
  OTHERS: { COLOR: '#8d9690', POS: 3 },
};
const RELATION_COLORS = {
  EMPLOYEE: { COLOR: '#0088FE', POS: 1 },
  CHILD: { COLOR: '#00C49F', POS: 2 },
  SPOUSE: { COLOR: '#FFBB28', POS: 3 },
  OTHER: { COLOR: '#8d9690', POS: 4 },
};

function CustomTooltip({ active, payload, label }) {
  const censusPayload = payload?.slice(0, 3);
  const benchmarkPayload = payload?.slice(3, 6);
  if (active && payload && payload?.length) {
    return (
      <Box style={{ background: Constants.WHITE, padding: '10px', border: '1px solid black' }}>
        <Box component="span">{label}</Box>
        <Box>
          <Box component="span" style={{ fontWeight: 'bold' }}>Census</Box>
          {censusPayload.map((pld) => (
            <Box key={pld.name} style={{ display: 'flex', alignItems: 'center' }}>
              <Box
                component="span"
                style={{
                  width: '10px',
                  height: '10px',
                  backgroundColor: pld?.fill,
                  marginRight: '5px',
                }}
              />
              <Box component="span">
                {`${pld?.name} : ${pld?.value}`}
              </Box>
            </Box>
          ))}
          <Box component="span" style={{ fontWeight: 'bold' }}>Benchmark</Box>
          {benchmarkPayload.map((pld) => (
            <Box key={pld.name} style={{ display: 'flex', alignItems: 'center' }}>
              <Box
                component="span"
                style={{
                  width: '10px',
                  height: '10px',
                  backgroundColor: pld?.fill,
                  marginRight: '5px',
                }}
              />
              <Box component="span">
                {`${pld?.name} : ${pld?.value}`}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }
  return null;
}

export default function Demograhpic(props) {
  const { bottomBarFunctions } = props;
  const pdfContentRef = useRef();
  const ageDistributionRef = useRef();
  const genderDistributionRef = useRef();
  const relationshipTiersRef = useRef();

  const { handleCancelCreation, goToNextPage, goToPreviousPage } = bottomBarFunctions;
  const demographicFetchFlag = useSelector((state) => state.scenario.demographicFetchFlag);
  const scenarioId = useSelector((state) => state.scenario.scenarioId);
  const ageDistribution = useSelector((state) => state.scenario.ageDistribution);
  const genderDistribution = useSelector((state) => state.scenario.genderDistribution);
  const relationshipDistribution = useSelector((state) => state.scenario.relationshipDistribution);
  const carrierName = useSelector(
    (state) => state.auth.carrierName,
  );
  // eslint-disable-next-line max-len
  const relationShipIndex = relationshipDistribution && Array.isArray(relationshipDistribution) ? [...Array(relationshipDistribution.length).keys()] : [];
  // eslint-disable-next-line max-len
  const genderIndex = genderDistribution && Array.isArray(genderDistribution) ? [...Array(genderDistribution.length).keys()] : [];

  useEffect(() => {
    if (scenarioId && scenarioId > 0) {
      store.dispatch(getDemographicById(scenarioId));
    } else {
      // store.dispatch(resetDemographic());
    }
  }, [demographicFetchFlag, scenarioId]);

  return (
    <Stack>
      <Grid container spacing={2} padding={3} ref={pdfContentRef}>
        <Grid item xs={12}>
          <Typography variant="h4">
            Demographics
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            Analyses of the provided census and what it means
            for your population&apos;s insurance risk.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Card ref={ageDistributionRef}>
            <Grid container spacing={2}>
              <Grid item xs={12} container variant="addPaddingBottom" justifyContent="space-between">
                <Typography variant="h5">
                  Age distribution
                </Typography>
                <ExportImage sectionRef={ageDistributionRef} sectionName="age_distribution" />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Compare your populations age distribution to the MultiPlan Benchmark,
                  which has been adjusted for  gender, industry and more.
                  An older population usually means higher claim incidence
                  rates in key coverages, such as cancer critical illness.
                </Typography>
                <Grid container>
                  <Grid item>
                    <Typography variant="h6" color="black" marginLeft={42}>Census</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" color="black" marginLeft={24}>Benchmark</Typography>
                  </Grid>
                </Grid>
                <ResponsiveContainer width="95%" height={300}>
                  <BarChart
                    data={ageDistribution}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="ageBand" />
                    <YAxis name="Percentage" />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                      verticalAlign="top"
                      formatter={(value, _, index) => (
                        <span style={{
                          color: 'black',
                          marginRight: index === 2 ? '30px' : 0,
                        }}
                        >
                          {value}
                        </span>
                      )}
                    />
                    <Bar dataKey="malePercentageOfCensus" stackId="census" fill="#0088FE" name="Male" />
                    <Bar dataKey="femalePercentageOfCensus" stackId="census" fill="#00C49F" name="Female" />
                    <Bar dataKey="otherPercentageOfCensus" stackId="census" fill="#808080" name="Other" stroke="#808080" />
                    <Bar dataKey="malePercentageOfBenchmark" stackId="benchmark" fill="#8ECEFD" name="Male" />
                    <Bar dataKey="femalePercentageOfBenchmark" stackId="benchmark" fill="#F88B9D" name="Female" />
                    <Bar
                      dataKey="otherPercentageOfBenchmark"
                      stackId="benchmark"
                      fill="#404040"
                      name="Other"
                      stroke="#404040"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card ref={genderDistributionRef}>
            <Grid container spacing={2}>
              <Grid item xs={12} container variant="addPaddingBottom" justifyContent="space-between">
                <Typography variant="h5">
                  Gender distribution
                </Typography>
                <ExportImage sectionRef={genderDistributionRef} sectionName="gender_distribution" />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Compare your population&apos;s gender distribution to the MultiPlan Benchmark,
                  which has been adjusted for age, industry and more.
                  Populations with different gender distributions usually
                  show different key critical illnesses.
                </Typography>
                <ResponsiveContainer width={800} height={500}>
                  <PieChart width={400} height={400}>
                    <Pie
                      activeIndex={genderIndex}
                      activeShape={(e) => renderActiveShape({
                        ...e, category: 'name', noText: false, COLORS: GENDER_COLORS,
                      })}
                      data={genderDistribution}
                      cx="50%"
                      cy="50%"
                      innerRadius={110}
                      outerRadius={130}
                      dataKey="percentageOfCensus"
                      fill="#8884d8"
                    />
                    <Pie
                      activeIndex={genderIndex}
                      activeShape={(e) => renderActiveShape({
                        ...e, category: 'name', noText: true, COLORS: GENDER_COLORS,
                      })}
                      data={genderDistribution}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={80}
                      dataKey="percentageOfBenchmark"
                      fill="#8884d8"
                    />
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card ref={relationshipTiersRef}>
            <Grid container spacing={2}>
              <Grid item xs={12} container variant="addPaddingBottom" justifyContent="space-between">
                <Typography variant="h5">
                  Relationship tiers
                </Typography>
                <ExportImage sectionRef={relationshipTiersRef} sectionName="relationship_tiers" />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Compare your population&apos;s relationship distribution to the MultiPlan
                  Benchmark.
                  Spouses and children have different claim risks and rates.
                </Typography>
                <ResponsiveContainer width={800} height={500}>
                  <PieChart width={400} height={400}>
                    <Pie
                      activeIndex={relationShipIndex}
                      activeShape={(e) => renderActiveShape({
                        ...e, category: 'name', noText: false, COLORS: RELATION_COLORS,
                      })}
                      data={relationshipDistribution}
                      cx="50%"
                      cy="50%"
                      innerRadius={110}
                      outerRadius={130}
                      dataKey="percentageOfCensus"
                      fill="#8884d8"
                    />
                    <Pie
                      activeIndex={relationShipIndex}
                      activeShape={(e) => renderActiveShape({
                        ...e, category: 'name', noText: true, COLORS: RELATION_COLORS,
                      })}
                      data={relationshipDistribution}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={80}
                      dataKey="percentageOfBenchmark"
                      fill="#8884d8"
                    />
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
            </Grid>
          </Card>
          <Grid item sx={12} className="printOnly">
            <CarrierDisclaimer />
          </Grid>
          <Grid item xs={12} className="printOnly">
            {Constants.PDF_MESSAGE.replace(Constants.CARRIER_TOKEN, carrierName)}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <BottomBar
          showExport
          exportPdfRef={pdfContentRef.current}
          firstButtonDisplayName="EXIT"
          secondButtonDisplayName="GO TO POLICIES"
          onClickFirstButton={handleCancelCreation}
          onClickSecondButton={goToNextPage}
          isBackButton
          onClickBackButton={goToPreviousPage}
        />
      </Grid>
    </Stack>
  );
}
