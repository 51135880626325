import { createTheme } from '@mui/material/styles';
import imageBackground from 'asset/login-background.svg';

export const PRIMARY_DARK = '#002D74';

export const PRIMARY = '#1976D2';

export const PRIMARY_WARNING = '#ff1744';

export const PRIMARY_35 = '#AECFEF';

export const PRIMARY_DEEP_DARK = '#002D74';

export const CHECK_GREEN = '#00BE5F';

export const RUNNING_ORANGE = '#FCBA03';

export const DISABLE_GRAY = '#A2A2A3';

export const WHITE = '#FFFFFF';

export const ERROR_LOGIN = '#88041e';
export const blueTheme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_35,
      dark: PRIMARY_35,
    },
  },
});

export const loginTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        backgroundImage: `url(${imageBackground})`,
      },
    },
  },
});

export const steps = () => [
  {
    label: 'Scenario Information',
  },
  {
    label: 'Demographics',
  },
  {
    label: 'Policies',
  },
  {
    label: 'Coverage',
    sublabel: ['Hospital Indemnity', 'Critical Illness', 'Accident'],
  },
  {
    label: 'Output',
  },
];

export const genderBenchmark = [
  {
    bmkPercentage: 52.5,
    bmkGender: 'male',
  },
  {
    bmkPercentage: 46.5,
    bmkGender: 'female',
  },
  {
    bmkPercentage: 1,
    bmkGender: 'others',
  },
];

export const relationshipBenchmark = [
  {
    bmkPercentage: 24.8,
    bmkRelationship: 'children',
  },
  {
    bmkPercentage: 61.6,
    bmkRelationship: 'employee',
  },
  {
    bmkPercentage: 13.6,
    bmkRelationship: 'spouse',
  },
];

export const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 });

export const intFormatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 });

export const doubleFormatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 });

export const percFormatterNoDecimal = new Intl.NumberFormat('en-US', { style: 'percent' });

export const precFormatter = new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 });

export const percFormatterMinZero = new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 0 });

export const calculateMaximumDay = (day, type) => {
  const maxDays = {
    nonicu: [0, 1, 1.52, 2.04, 2.55, 3.07, 3.59, 4.11, 4.63, 5.14, 5.18],
    icu: [0, 1, 1.90, 2.80, 3.70, 4.60, 5.49, 6.39, 7.29, 8.19, 8.99],
    rehab: [0, 1, 1.95, 2.85, 3.75, 4.65, 5.55, 6.45, 7.35, 8.25, 9],
    nonicu_aflac: [0, 1.00, 1.52, 2.04, 2.55, 3.07, 3.59, 4.11, 4.63, 5.14, 5.18],
    icu_aflac: [0, 1.00, 1.90, 2.80, 3.70, 4.60, 5.49, 6.39, 7.29, 8.19, 8.99],
    stepdown_aflac: [0, 1.00, 1.70, 2.40, 3.10, 3.80, 4.51, 5.21, 5.91, 6.61, 7.01],
    ov_aflac:
      [0, 0.246, 0.425, 0.558, 0.657, 0.732, 0.789, 0.832, 0.866, 0.892, 0.912, 0.928, 1.000],
    telemed_aflac:
      [0, 0.526, 0.728, 0.829, 0.888, 0.922, 0.945, 0.960, 0.971, 0.978, 0.983, 0.987, 1.000],
    chiro_aflac:
      [0, 0.149, 0.266, 0.361, 0.441, 0.509, 0.569, 0.620, 0.665, 0.705, 0.740, 0.771, 1.000],
    er_visit_aflac:
      [0, 0.675, 0.859, 0.926, 0.956, 0.971, 0.979, 0.985, 0.988, 0.990, 0.992, 0.993, 0.994],
    rehab_aflac: [0, 1.00, 1.95, 2.85, 3.75, 4.65, 5.55, 6.45, 7.35, 8.25, 9.00],
    prescription_aflac:
      [0, 0.044, 0.087, 0.129, 0.170, 0.209, 0.247, 0.283, 0.318, 0.352, 0.384, 0.415, 0.445],
    nonicu_allstate: [0, 1, 1.31, 1.62, 1.93, 2.24, 2.55, 2.86, 3.17, 3.10, 3.10],
    icu_allstate: [0, 1, 1.54, 2.08, 2.62, 3.16, 3.7, 4.24, 4.78, 5.32, 5.4],
    ov_allstate:
      [0, 0.246, 0.425, 0.558, 0.657, 0.732, 0.789, 0.832, 0.866, 0.892, 0.912, 0.928, 1],
    acc_icu_allstate: [0, 1, 1.9, 2.8, 3.7, 4.6, 5.49, 6.39, 7.29, 8.19, 8.99],
    acc_nonicu_allstate: [0, 1, 1.52, 2.04, 2.55, 3.07, 3.59, 4.11, 4.63, 5.14, 5.18],
    acc_rehab_allstate: [0, 1, 1.95, 2.85, 3.75, 4.65, 5.55, 6.45, 7.35, 8.25, 9],
    acc_stepdown_allstate: [0, 1, 1.7, 2.4, 3.1, 3.8, 4.51, 5.21, 5.91, 6.61, 7.01],
    acc_nonicu_bstbroker: [0, 1, 1.52, 2.04, 2.55, 3.07, 3.59, 4.11, 4.63, 5.14, 5.18],
    acc_icu_bstbroker: [0, 1, 1.9, 2.8, 3.7, 4.6, 5.49, 6.39, 7.29, 8.19, 8.99],
  };
  if (parseInt(day, 10) < 0 || !Number.isInteger(parseInt(day, 10))) { return 0; }
  if (type in maxDays) {
    const n = maxDays[type].length;
    return maxDays[type][Math.min(parseInt(day, 10), n - 1)];
  }
  return 0;
};

export const bestCodes = {
  BST: {
    HI: ['wellnessVisit', 'nonIcuAdmission', 'nonIcuPerDiem'],
    CI: ['wellness'],
    AC: ['emergency', 'nonIcuAdmission', 'nonIcuPerDiem', 'groundAmbu', 'xray', 'initialDoctorVisit', 'followUp', 'urgentCare'],
  },
  VOYA: {
    HI: ['wellnessVisit', 'nonIcuAdmission', 'nonIcuPerDiem'],
    CI: ['wellness'],
    AC: ['emergency', 'nonIcuAdmission', 'nonIcuPerDiem', 'groundAmbu', 'xray', 'initialDoctorVisit', 'followUp', 'urgentCare'],
  },
  AFLAC: {
    HI: ['healthScreening', 'admission', 'confinement', 'erVisit'],
    CI: ['healthScreen'],
    AC: ['healthScreening', 'emergency', 'hospitalAdmission', 'hospitalConfinement', 'groundAmbulance', 'xrayUrgent', 'xrayDoctor', 'initialTreatOffice', 'initialTreatUrgent', 'accDoOne'],
  },
  ALLSTATE: {
    HI: ['firstDayHospitalConfinement', 'dailyHospitalConfinement', 'hospitalIntensiveCare'],
    CI: ['heartAttack', 'esrdFailure', 'majorOrganTransplant', 'stroke', 'cad'],
    AC: ['dailyHospitalConfinement', 'accUrgentCare', 'accXray', 'accEmergencyRoom', 'accDoOne', 'medicalEquip'],
  },
};

export const pageSizeOptionList = [10, 25, 50, 100];
export const defaultPageSize = pageSizeOptionList[0];
export const defaultOrder = 'DESC';

export const CARRIER_TOKEN = '{CARRIER}';
export const PDF_MESSAGE = `** THIS IS FOR ${CARRIER_TOKEN} INTERNAL USE ONLY!`;

export const POLICY_MODULE_TYPE = { PERIOD: 'PERIOD', ORIGINAL: 'ORIGINAL', FRACT: 'FRACT' };
export const POLICY_ITEM_TYPE = {
  PERIOD: 'PERIOD', ORIGINAL: 'ORIGINAL', FRACT: 'FRACT', PERCENTAGE: 'PERCENTAGE',
};
export const POLICY_CODE = { HI: 'HI', CI: 'CI', AC: 'AC' };

export const VOYA = {
  CODE: 'VOYA',
  PATHNAME: '/voya',
};
export const AFLAC = {
  CODE: 'AFLAC',
  PATHNAME: '/aflac',
};
export const BST = {
  CODE: 'BST',
  PATHNAME: '/login',
  PATHNAME_DEFAULT: '/',
};

export const WELLNESS_BENEFIT_MONEY_SPENT = 'Wellness benefit money spent';
export const MONEY_SPENT_DEFAULT = 'Projected Claims';
export const TOOLTIP_CONTENT = 'The projected claims in the modules below are determined by the values included in the General Policy Provisions.';

export const STATUS_CODE = {
  PYTHON_INVALID: 'PYTHON_INVALID',
  RUNNING: 'RUNNING',
  SUCCESS: 'SUCCESS',
};

export const CENSUS_FILE_REMINDER_CONTENT = {
  FILE_INVALID: 'Your current census file is likely in the wrong format. Please upload a valid census file and rerun the scenario.',
  FILE_SUCCESS: 'Your scenario has been successfully processed!',
};

export const DEPENDENCY_OBJ_LIST = [
  {
    code: 'TOTAL',
    claimCode: 'totalCount',
    CICode: '',
  },
  {
    code: 'EMPLOYEE',
    claimCode: 'employeeCount',
    CICode: 'faceValue',
  },
  {
    code: 'SPOUSE',
    claimCode: 'spouseCount',
    CICode: 'adultDp',
  },
  {
    code: 'CHILDREN',
    claimCode: 'childrenCount',
    CICode: 'minorDp',
  },
];

export const getBestBenefitPrice = (price) => Math.round(
  Math.ceil((price * 1.25) / 25) * 25,
);

export const DOMAIN_PREFIX_REGEX = /^https?:\/\/[^/]+/;

export const specificBenefitChecked = {
  BST: {
    HI: 'wellnessVisitChecked',
    CI: 'wellnessChecked',
    AC: 'wellnessChecked',
  },
  VOYA: {
    HI: 'wellnessVisitChecked',
    CI: 'wellnessChecked',
    AC: 'wellnessChecked',
  },
  AFLAC: {
    HI: 'healthScreeningChecked',
    CI: 'healthScreenChecked',
    AC: 'healthScreeningChecked',
  },
  ALLSTATE: {
    HI: 'wellnessVisitChecked',
    CI: 'healthScreenChecked',
    AC: 'healthScreeningChecked',
  },
};

export const policyDisplayNames = {
  HI: 'Hospital Indemnity',
  CI: 'Critical Illness',
  AC: 'Accident',
};

export const fixedProductPriority = {
  AC: 1,
  HI: 2,
  CI: 3,
};

export const avgHospitalBenefits = {
  BST: {
    benefit1: 'nonIcuAdmission',
    benefit1Checked: 'nonIcuAdmissionChecked',
    benefit2: 'nonIcuPerDiem',
    benefit2Checked: 'nonIcuPerDiemChecked',
  },
  AFLAC: {
    benefit1: 'admission',
    benefit1Checked: 'admissionChecked',
    benefit2: 'confinement',
    benefit2Checked: 'confinementChecked',
  },
  ALLSTATE: {
    benefit1: 'firstDayHospitalConfinement',
    benefit1Checked: 'firstDayHospitalConfinementChecked',
    benefit2: 'dailyHospitalConfinement',
    benefit2Checked: 'dailyHospitalConfinementChecked',
  },
};
