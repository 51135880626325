/* eslint-disable max-len */
import * as React from 'react';
import {
  Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText,
} from '@mui/material';
import { useSelector } from 'react-redux';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import RoutePath from 'app/router/RoutePath';
import { resetJwt } from 'features/login/state/reducer';
import store from 'app/store';
import { Drawer, DrawerHeader } from 'common/components/navigatorComponents';
import { VOYA, AFLAC } from 'common/Constants';
import ChevronleftIcon from '@mui/icons-material/ChevronLeft';
import CancelScenarioDialog from 'features/scenario/common/cancelScenarioDialog';
import { updateFormState } from 'features/scenario/state/reducer';
import { toggleSideBar } from './state/reducer';

export default function SideBar() {
  const navigate = useNavigate();
  const carrierCode = useSelector((state) => state.auth.carrierCode);
  const sideBarOpen = useSelector((state) => state.navigator.sideBarOpen);
  const formStateValue = useSelector((state) => state.scenario.isModified);
  const [path, setPath] = React.useState(false);
  const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false);

  const toggleMenu = () => {
    store.dispatch(toggleSideBar());
  };

  const navigateTo = (link, screen) => {
    if (screen || !formStateValue) {
      setCancelDialogOpen(false);
      store.dispatch(updateFormState(false));
      navigate(link);
      setPath(false);
    } else {
      setCancelDialogOpen(true);
      setPath(link);
    }
  };

  const logout = (screen) => {
    if (screen || !formStateValue) {
      setCancelDialogOpen(false);
      localStorage.clear();
      store.dispatch(resetJwt());
      if (carrierCode === VOYA.CODE) {
        navigate(RoutePath.voyaLogin.path);
      } else if (carrierCode === AFLAC.CODE) {
        navigate(RoutePath.aflacLogin.path);
      } else {
        navigate(RoutePath.login.path);
      }
      store.dispatch(updateFormState(false));
    } else {
      setCancelDialogOpen(true);
    }
  };

  const cancelScenarioClose = () => {
    setCancelDialogOpen(false);
  };

  return (
    <Drawer
      variant="permanent"
      open={sideBarOpen}
      sx={{
        flexShrink: 0,
      }}
    >
      <DrawerHeader>
        <IconButton onClick={() => toggleMenu()}>
          <ChevronleftIcon />
        </IconButton>
      </DrawerHeader>
      <List>
        <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton onClick={() => navigateTo(RoutePath.app.scenario.path)}>
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: sideBarOpen ? 3 : 'auto',
                justifyContent: 'center',
              }}
            />
            <AddBusinessIcon />
            <ListItemText primary="Scenarios" sx={{ opacity: sideBarOpen ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton onClick={() => logout()}>
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: sideBarOpen ? 3 : 'auto',
                justifyContent: 'center',
              }}
            />
            <LogoutIcon />
            <ListItemText primary="Logout" sx={{ opacity: sideBarOpen ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      </List>
      <CancelScenarioDialog
        logout={logout}
        navigateTo={navigateTo}
        path={path}
        isOpen={cancelDialogOpen}
        onClose={() => cancelScenarioClose(false)}
      />
    </Drawer>
  );
}
