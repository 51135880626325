import React, { useEffect, useState } from 'react';
import {
  Table, Accordion, AccordionDetails, Grid,
} from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  formatter, calculateMaximumDay, intFormatter, POLICY_ITEM_TYPE, POLICY_MODULE_TYPE,
} from 'common/Constants';
import { AFLAC_CARRIER_CODE } from 'common/AppConstant';
import { StyledTableCell, StyledTableRow } from '../common/styledTableComponent';
import PolicyTableHead from '../common/policyTableHead';
import PolicyTableCheckboxCell from '../common/policyTableCheckboxCell';
import PolicyTableInputCell from '../common/policyTableInputCell';
import PolicyAccordionSummary from '../common/policyAccordionSummary';

export default function Accident({ setAccidentFormValues }) {
  const { getValues, setValue, watch } = useFormContext();

  const carrierCode = useSelector((state) => state.auth?.carrierCode);

  const claim = useSelector((state) => state.scenario.claim);

  const claimWeight = useSelector(
    (state) => state.scenario.claimWeight,
  );
  const accidentCategoryCode = useSelector(
    (state) => state.scenario.ACCategoryCode,
  );

  const ACModuleList = useSelector((state) => state.scenario.ACModuleList);

  const blankFlagObject = {};
  ACModuleList.forEach((item) => {
    blankFlagObject[item.code] = null;
  });

  const watcher = [];
  ACModuleList.forEach((moduleItem) => {
    watcher.push([]);
    accidentCategoryCode[moduleItem.code].forEach((item) => {
      watcher[watcher.length - 1].push(watch(`${item.name}Checked`));
    });
  });

  const [checkedChangingFlag, setCheckedChangingFlag] = useState(false);
  const [payout, setPayout] = useState({});
  const [totalClaims, setTotalClaims] = useState({});
  const [totalSpent, setTotalSpent] = useState({});
  const [expanded, setExpanded] = useState(blankFlagObject);
  const [headerChecked, setHeaderChecked] = useState(blankFlagObject);
  const [headerIndeterminate, setHeaderIndeterminate] = useState(blankFlagObject);

  const setHeaderCheckboxStatus = () => {
    const output = {};
    watcher.forEach((flag, index) => {
      const n = flag.length;
      const m = flag.reduce((acc, val) => acc + val, 0);
      if (m === 0) {
        setHeaderChecked((prev) => ({ ...prev, [ACModuleList[index].code]: false }));
        setHeaderIndeterminate((prev) => ({ ...prev, [ACModuleList[index].code]: false }));
        output[ACModuleList[index].code] = true;
      } else if (m === n) {
        setHeaderChecked((prev) => ({ ...prev, [ACModuleList[index].code]: true }));
        setHeaderIndeterminate((prev) => ({ ...prev, [ACModuleList[index].code]: false }));
        output[ACModuleList[index].code] = false;
      } else {
        setHeaderChecked((prev) => ({ ...prev, [ACModuleList[index].code]: false }));
        setHeaderIndeterminate((prev) => ({ ...prev, [ACModuleList[index].code]: true }));
        output[ACModuleList[index].code] = true;
      }
    });
    return output;
  };

  const updateAllPayout = () => {
    const tempPayout = {};

    Object.entries(accidentCategoryCode).forEach((list) => {
      list[1].forEach((item) => {
        if (item.code in claim) {
          if (item.type === POLICY_ITEM_TYPE.FRACT) {
            tempPayout[item.name] = Math.round((getValues(`${item.name}Closed`) * item.fractureInjuriesWeight
              + getValues(`${item.name}Open`) * (1 - item.fractureInjuriesWeight)) * Math.round(claim[item.code].totalCount * claimWeight.AC));
          } else if (item.type === POLICY_ITEM_TYPE.PERIOD) {
            tempPayout[item.name] = Math.round(getValues(item.name) * Math.round(
              claim[item.code].totalCount * claimWeight.AC,
            ) * calculateMaximumDay(getValues(item.periodName), item.periodCode));
          } else {
            tempPayout[item.name] = getValues(item.name) * Math.round(
              claim[item.code].totalCount * claimWeight.AC,
            );
          }
        }
      });
    });

    Object.entries(accidentCategoryCode).forEach((list) => {
      list[1].forEach((item) => {
        if (item.code in claim) {
          setPayout((prev) => ({
            ...prev,
            [item.name]: tempPayout[item.name],
          }));
        }
      });
    });

    ACModuleList.forEach((moduleItem) => {
      setTotalSpent((prev) => ({
        ...prev,
        [moduleItem.code]: accidentCategoryCode[moduleItem.code].reduce((
          acc,
          item,
        ) => (item.name in tempPayout
          ? acc + tempPayout[item.name] * Number(getValues(`${item.name}Checked`))
          : acc), 0),
      }));
    });
  };

  const onClickHeaderCheckbox = (key) => {
    const flag = setHeaderCheckboxStatus();
    accidentCategoryCode[key].forEach((item) => {
      setValue(`${item.name}Checked`, flag[key]);
    });
    setCheckedChangingFlag(!checkedChangingFlag);
    updateAllPayout();
  };

  const onCheckedFieldChange = () => {
    setCheckedChangingFlag(!checkedChangingFlag);
  };

  useEffect(() => {
    updateAllPayout();

    ACModuleList.forEach((moduleItem) => {
      setTotalClaims((prev) => ({
        ...prev,
        [moduleItem.code]: accidentCategoryCode[moduleItem.code].reduce((acc, item) => {
          if (item.code in claim) {
            if ((item.type === POLICY_ITEM_TYPE.PERIOD || item.name === 'facilityFees') && item.relatedName !== null && getValues(`${item.relatedName}Checked`) !== undefined) {
              return acc + Math.round(claim[item.code].totalCount * claimWeight.AC) * Math.min(Number(getValues(`${item.name}Checked`))
                + Number(getValues(`${item.relatedName}Checked`)), 1)
                - Math.round(claim[item.code].totalCount * claimWeight.AC) * Number(getValues(`${item.relatedName}Checked`));
            }
            return acc + Math.round(claim[item.code].totalCount * claimWeight.AC) * Number(getValues(`${item.name}Checked`));
          }
          return acc;
        }, 0),
      }));
    });
  }, [claim, claimWeight, checkedChangingFlag]);

  useEffect(() => {
    setHeaderCheckboxStatus();
    updateAllPayout();
  }, []);

  useEffect(() => {
    setHeaderCheckboxStatus();
  }, [checkedChangingFlag]);

  useEffect(() => {
    const allItems = Object.values(accidentCategoryCode).flat();
    const itemNames = allItems.flatMap((item) => [item.name, `${item.name}Checked`]);
    const filteredValues = Object.keys(getValues()).reduce((acc, key) => {
      if (itemNames.includes(key)) {
        return { ...acc, [key]: getValues(key) };
      }
      return acc;
    }, {});
    setAccidentFormValues(filteredValues);
    const subscription = watch((values) => {
      const filteredValuesLatest = Object.keys(values).reduce((acc, key) => {
        if (itemNames.includes(key)) {
          return { ...acc, [key]: getValues(key) };
        }
        return acc;
      }, {});
      setAccidentFormValues(filteredValuesLatest);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Grid container spacing={2}>
      {ACModuleList.map((moduleItem) => (
        accidentCategoryCode[moduleItem.code].length > 0
        // temporary hide these 2 sections due to mis-calculations for Aflac
        && ((carrierCode !== AFLAC_CARRIER_CODE) || (moduleItem.code !== 'lifeChangingEvents' && moduleItem.code !== 'commonInjuriesDislocations'))
        && (
          <Grid item xs={12}>
            <Accordion expanded={expanded[moduleItem.code]}>
              <PolicyAccordionSummary
                id={`AC-${moduleItem.code}`}
                title={moduleItem.displayName}
                onChange={onClickHeaderCheckbox}
                setExpanded={setExpanded}
                moduleCode={moduleItem.code}
                checked={headerChecked}
                indeterminate={headerIndeterminate}
                totalClaims={totalClaims[moduleItem.code]}
                totalSpent={totalSpent[moduleItem.code]}
              />
              <AccordionDetails sx={{ padding: 0 }}>
                <TableContainer component={Paper} margin={0}>
                  <Table aria-label="main table">
                    <PolicyTableHead type={moduleItem.tableType} />
                    <TableBody>
                      {accidentCategoryCode[moduleItem.code].map((item) => (
                        <StyledTableRow>
                          <PolicyTableCheckboxCell
                            name={`${item.name}Checked`}
                            onChange={onCheckedFieldChange}
                          />
                          <StyledTableCell align="left" component="th" scope="row">
                            {item.displayName}
                          </StyledTableCell>

                          {moduleItem.tableType === POLICY_MODULE_TYPE.ORIGINAL
                            && (
                              <PolicyTableInputCell
                                name={item.name}
                                onChange={updateAllPayout}
                                adornment="dollar"
                              />
                            )}

                          {moduleItem.tableType === POLICY_MODULE_TYPE.PERIOD
                            && (
                              <PolicyTableInputCell
                                name={item.name}
                                onChange={updateAllPayout}
                                adornment="dollar"
                              />
                            )}

                          {moduleItem.tableType === POLICY_MODULE_TYPE.PERIOD
                            && (item.type === POLICY_ITEM_TYPE.PERIOD
                              ? (
                                <PolicyTableInputCell
                                  name={item.periodName}
                                  onChange={updateAllPayout}
                                  max={100}
                                />
                              )
                              : <StyledTableCell />)}

                          {moduleItem.tableType === POLICY_MODULE_TYPE.FRACT
                            && (
                              <>
                                <PolicyTableInputCell
                                  name={`${item.name}Closed`}
                                  onChange={updateAllPayout}
                                  adornment="dollar"
                                />
                                <PolicyTableInputCell
                                  name={`${item.name}Open`}
                                  onChange={updateAllPayout}
                                  adornment="dollar"
                                />
                              </>
                            )}

                          <StyledTableCell align="left">
                            {item.code in claim
                              ? intFormatter.format(
                                Math.round(claim[item.code].totalCount * claimWeight.AC),
                              ) : null}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {item.name in payout ? formatter.format(payout[item.name]) : null}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )

      ))}

    </Grid>
  );
}
