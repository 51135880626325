import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle, Grid, Input, Typography,
} from '@mui/material';
import DOMPurify from 'dompurify';
import store from 'app/store';
import { acceptTerms, getEula } from 'features/user/state/service';
import { resetJwt } from 'features/login/state/reducer';
import { React, useState, useEffect } from 'react';
import bstLogo from 'asset/bst-logo.svg';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RoutePath from 'app/router/RoutePath';
import { VOYA, AFLAC } from './Constants';

export default function UserAgreement({ open, onClose }) {
  const [checked, setChecked] = useState(false);
  const eula = useSelector((state) => state.user.eula);
  const sanitizedEula = DOMPurify.sanitize(eula);
  const carrierCode = useSelector((state) => state.auth.carrierCode);
  const navigate = useNavigate();

  const onClickAccept = () => {
    store.dispatch(acceptTerms()).then(() => {
      onClose();
    });
  };

  const onCheckHandler = () => {
    setChecked(!checked);
  };

  const navigateTo = (path) => {
    navigate(path);
  };

  const logout = () => {
    localStorage.clear();
    store.dispatch(resetJwt());
    if (carrierCode === VOYA.CODE) {
      navigateTo(RoutePath.voyaLogin.path);
    } else if (carrierCode === AFLAC.CODE) {
      navigateTo(RoutePath.aflacLogin.path);
    } else {
      navigateTo(RoutePath.login.path);
    }
  };

  useEffect(() => {
    store.dispatch(getEula());
  }, []);

  return (
    <Dialog
      open={open}
      scroll="paper"
      fullWidth
      maxWidth="lg"
    >
      <Grid
        paddingTop={4}
        paddingLeft={3.5}
      >
        <img
          src={bstLogo}
          alt="BST"
          className="app-logo"
          height="40"
        />
      </Grid>
      <DialogTitle>Benefits Science End User License Agreement</DialogTitle>
      <DialogContent divider>
        {/* eslint-disable-next-line */}
        <div dangerouslySetInnerHTML={{ __html: sanitizedEula }} />
      </DialogContent>
      <DialogActions
        autoFocus
        color="primary"
      >
        <Grid container flexDirection="row" paddingTop={2} paddingLeft={2}>
          <Grid container xs={6} flexDirection="row">
            <Grid item>
              <Input
                type="checkbox"
                checked={checked}
                onChange={onCheckHandler}
                disableUnderline
              />
            </Grid>
            <Grid item>
              <Typography>&nbsp; I have read and agreed the above terms</Typography>
            </Grid>
          </Grid>
          <Grid container xs={6} justifyContent="flex-end" paddingRight={2} paddingBottom={1}>
            <Button
              variant="outlined"
              sx={{ marginRight: '15px' }}
              onClick={logout}
            >
              Cancel
            </Button>
            <Button
              disabled={!checked}
              color="primary"
              variant="contained"
              onClick={() => {
                onClickAccept();
              }}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
