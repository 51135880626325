import React, { useState, useEffect } from 'react';
import {
  Card, Grid, Typography, Divider, Box, Table, TableHead, TableBody,
  TableContainer, TableRow, Paper, Button,
} from '@mui/material';
import { useSelector } from 'react-redux';
import BottomBar from 'features/navigation/bottomBar';
import {
  steps, PRIMARY_DARK, formatter, intFormatter, POLICY_CODE,
} from 'common/Constants';
import { grey } from '@mui/material/colors';
import store from 'app/store';
import { StyledTableCell, StyledTableRow } from './common/styledTableComponent';
import {
  saveHospitalIndemnity, saveCriticalIllness, saveAccident,
} from './state/service';

export default function Coverage(props) {
  const { bottomBarFunctions } = props;
  const { handleCancelCreation, goToNextPage, goToPreviousPage } = bottomBarFunctions;

  const subpage = useSelector((state) => state.scenario.coveragePage);
  const currPage = useSelector((state) => state.scenario.scenarioSection);

  const scenarioId = useSelector((state) => state.scenario.scenarioId);

  const totalPayout = useSelector(
    (state) => state.scenario.totalPayout,
  );

  const totalCount = useSelector(
    (state) => state.scenario.totalCount,
  );

  const HIPayoutDetail = useSelector(
    (state) => state.scenario.HIPayoutDetail,
  );
  const hospitalIndemnityObject = useSelector(
    (state) => state.scenario.hospitalIndemnityObject,
  );

  const CIPayoutDetail = useSelector(
    (state) => state.scenario.CIPayoutDetail,
  );
  const criticalIllnessObject = useSelector(
    (state) => state.scenario.criticalIllnessObject,
  );

  const ACPayoutDetail = useSelector(
    (state) => state.scenario.ACPayoutDetail,
  );
  const accidentObject = useSelector(
    (state) => state.scenario.accidentObject,
  );

  const [benefitList, setBenefitList] = useState([]);

  const getCheckedStatus = (item) => {
    if (item.policyCode === POLICY_CODE.HI) {
      return hospitalIndemnityObject[`${item.name}Checked`];
    } if (item.policyCode === POLICY_CODE.CI) {
      return criticalIllnessObject[`${item.name}Checked`];
    } if (item.policyCode === POLICY_CODE.AC) {
      return accidentObject[`${item.name}Checked`];
    }
    return null;
  };

  const onClickPolicyButton = (item) => {
    const temp = {};
    if (item.policyCode === POLICY_CODE.HI) {
      Object.assign(temp, hospitalIndemnityObject);
      temp[`${item.name}Checked`] = !hospitalIndemnityObject[`${item.name}Checked`];
      temp.scenarioId = scenarioId;
      store.dispatch(saveHospitalIndemnity(temp));
    }
    if (item.policyCode === POLICY_CODE.CI) {
      Object.assign(temp, criticalIllnessObject);
      temp[`${item.name}Checked`] = !criticalIllnessObject[`${item.name}Checked`];
      temp.scenarioId = scenarioId;
      store.dispatch(saveCriticalIllness(temp));
    }
    if (item.policyCode === POLICY_CODE.AC) {
      Object.assign(temp, accidentObject);
      temp[`${item.name}Checked`] = !accidentObject[`${item.name}Checked`];
      temp.scenarioId = scenarioId;
      store.dispatch(saveAccident(temp));
    }
  };

  useEffect(() => {
    const tempList = [];
    [...HIPayoutDetail, ...CIPayoutDetail, ...ACPayoutDetail].forEach((item) => {
      if ('payout' in item) {
        tempList.push(item);
      }
    });
    tempList.sort((a, b) => ((a.payout.TOTAL < b.payout.TOTAL) ? 1 : -1));
    setBenefitList(tempList);
  }, [currPage]);

  return (
    <>
      <Grid container spacing={2} padding={3}>
        <Grid item xs={12}>
          <Typography variant="h4">
            Coverage for
            {' '}
            {steps()[3].sublabel[subpage]}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            Here you can take a deep dive into each product, see the most
            relevant benefits, and add/remove benefits to increase the
            product&apos;s overall efficiency and fit for your population.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <Grid container spacing={2}>
              <Grid item xs={12} variant="addPaddingBottom">
                <Typography variant="h5">
                  {`Quick Insights - ${steps()[3].sublabel[subpage]}`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid container spacing={2} item xs={12}>
                <Grid item xs={4}>
                  <Box
                    maxWidth
                    sx={{
                      border: 1,
                      borderRadius: '9px',
                      borderColor: grey[400],
                    }}
                  >
                    <Typography
                      variant="h3"
                      align="center"
                      color={PRIMARY_DARK}
                      sx={{ zIndex: 1450, margin: '24px', fontWeight: '600' }}
                    >
                      {subpage === 0 && intFormatter.format(totalCount.HI.count.TOTAL)}
                      {subpage === 1 && intFormatter.format(totalCount.CI.count.TOTAL)}
                      {subpage === 2 && intFormatter.format(totalCount.AC.count.TOTAL)}
                    </Typography>
                    <Typography align="center" color={grey[700]} marginBottom={2}>
                      Projected Number of Claims
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={4}>
                  <Box
                    maxWidth
                    sx={{
                      border: 1,
                      borderRadius: '9px',
                      borderColor: grey[400],
                    }}
                  >
                    <Typography
                      variant="h3"
                      align="center"
                      color={PRIMARY_DARK}
                      sx={{ zIndex: 1450, margin: '24px', fontWeight: '600' }}
                    >
                      {subpage === 0 && (totalPayout.HI.payout.TOTAL === 0 ? 0
                        : formatter.format(
                          (totalPayout.HI.payout.TOTAL / totalCount.HI.count.TOTAL) || 0,
                        ))}
                      {subpage === 1 && (totalPayout.CI.payout === 0 ? 0
                        : formatter.format(
                          (totalPayout.CI.payout.TOTAL / totalCount.CI.count.TOTAL) || 0,
                        ))}
                      {subpage === 2 && (totalPayout.AC.payout.TOTAL === 0 ? 0
                        : formatter.format(
                          (totalPayout.AC.payout.TOTAL / totalCount.AC.count.TOTAL) || 0,
                        ))}
                    </Typography>
                    <Typography align="center" color={grey[700]} marginBottom={2}>
                      Average Claim
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={4}>
                  <Box
                    maxWidth
                    sx={{
                      border: 1,
                      borderRadius: '9px',
                      borderColor: grey[400],
                    }}
                  >
                    <Typography
                      variant="h3"
                      align="center"
                      color={PRIMARY_DARK}
                      sx={{ zIndex: 1450, margin: '24px', fontWeight: '600' }}
                    >
                      {subpage === 0 && formatter.format(totalPayout.HI.payout.TOTAL)}
                      {subpage === 1 && formatter.format(totalPayout.CI.payout.TOTAL)}
                      {subpage === 2 && formatter.format(totalPayout.AC.payout.TOTAL)}
                    </Typography>
                    <Typography align="center" color={grey[700]} marginBottom={2}>
                      Projected Claims
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <Grid container spacing={2}>
              <Grid item xs={12} variant="addPaddingBottom">
                <Typography variant="h5">
                  {`Detail View - ${steps()[3].sublabel[subpage]}`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  All benefits from most value to least value
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper} margin={0}>
                  <Table aria-label="main table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left">
                          {' '}
                          <Typography fontWeight="500">Benefit</Typography>
                          {' '}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {' '}
                          <Typography fontWeight="500">Average Projected Claim</Typography>
                          {' '}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {' '}
                          <Typography fontWeight="500">Claims</Typography>
                          {' '}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {' '}
                          <Typography fontWeight="500">Projected Claims</Typography>
                          {' '}
                        </StyledTableCell>
                        <StyledTableCell align="right" />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {benefitList.map((item) => (
                        ((item.policyCode === POLICY_CODE.HI && subpage === 0)
                          || (item.policyCode === POLICY_CODE.CI && subpage === 1)
                          || (item.policyCode === POLICY_CODE.AC && subpage === 2)) && 'payout' in item
                          ? (
                            <StyledTableRow>
                              <StyledTableCell
                                align="left"
                                component="th"
                                scope="row"
                                style={{ width: '30%' }}
                              >
                                {item.displayName}
                              </StyledTableCell>
                              <StyledTableCell align="center" style={{ width: '17%' }}>
                                {formatter.format(
                                  item.payout.TOTAL === 0 || item.count.TOTAL === 0 ? 0
                                    : item.payout.TOTAL / item.count.TOTAL,
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="center" style={{ width: '14%' }}>
                                {intFormatter.format(item.count.TOTAL)}
                              </StyledTableCell>
                              <StyledTableCell align="right" style={{ width: '14%' }}>
                                {formatter.format(item.payout.TOTAL)}
                              </StyledTableCell>
                              <StyledTableCell align="center" style={{ width: '25%' }}>
                                <Button
                                  variant={getCheckedStatus(item) ? 'contained' : 'outlined'}
                                  onClick={() => onClickPolicyButton(item)}
                                >
                                  {getCheckedStatus(item) ? 'REMOVE BENEFIT' : 'ADD BENEFIT'}
                                </Button>
                              </StyledTableCell>
                            </StyledTableRow>
                          )
                          : null
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <BottomBar
          firstButtonDisplayName="EXIT"
          secondButtonDisplayName="GO TO OUTPUT"
          onClickFirstButton={handleCancelCreation}
          onClickSecondButton={goToNextPage}
          isBackButton
          onClickBackButton={goToPreviousPage}
        />
      </Grid>
    </>
  );
}
